import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@tanstack/react-query";
import { api } from "../../../api/UseApi";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import Stats from "./Stats";
import SuccessfulSOS from "./SuccessfulSOS";
import WorldMap from "./WorldMap";
import { BASE_URL, GET_REGISTERED_USER } from "../../../api/ApiEndPoints";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const navigate = useNavigate();
  useEffect(() => {
    checkAuthentication();
    fetchData();
    getDownloads();
  }, []);

  const checkAuthentication = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token.token == "") {
        // navigate("/auth/sign-in");
      }
    } else {
      // navigate("/auth/sign-in");
    }
  };

  const { t } = useTranslation();
  const [totalRegisteredUsers, setTotalRegisteredUsers] = useState("0");
  const [iosDownloads, setIosDownloads] = useState("0");
  const [androidDownloads, setAndroidDownloads] = useState("0");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/registered-users-count",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const count = response.data?.Count;
      console.log("Registered User Count:", count);

      setTotalRegisteredUsers(count);
    } catch (error) {
      console.log(error);
    }
  };

  const getDownloads = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/registered-users-mobiletype",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setAndroidDownloads(response.data?.Android);
      setIosDownloads(response.data?.Ios);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <Stats
            title="Total Registered Users"
            amount={totalRegisteredUsers}
            chip=""
            percentagetext="246K"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <Stats
            title="IOS Downloads"
            amount={iosDownloads}
            chip="Annual"
            percentagetext="-5%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl>
          <Stats
            title="Android Downloads"
            amount={androidDownloads}
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <BarChart />
        </Grid>
      </Grid>
      <Grid container spacing={6} direction="row">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid item xs={12}>
            {/* <LeelouDownloads td={iosDownloads + androidDownloads} /> */}
          </Grid>
          <Grid item xs={12}>
            <SuccessfulSOS />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid item xs={12}>
            <WorldMap />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
