import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { backgrounds, darken } from "polished";
import { grey, yellow, red, green, purple } from "@material-ui/core/colors";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SosDetails from "./SosDetails";
import stringData from "../stringFile";

import {
  Avatar,
  Button,
  CircularProgress,
  Link,
  Box,
  Menu,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter } from "react-feather";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
  Search as SearchIcon,
  FilterList as FilterIcon,
  ArrowBack as LeftArrow,
  Clear,
} from "@material-ui/icons";
import { enableCursor } from "@fullcalendar/react";
import SosUserDetails from "./SosUserDetail";
const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const PendingChip = styled(Chip)({
  //pending cancel
  backgroundColor: "#e5a83a",
  color: "#fff",
});
const CancelledChip = styled(Chip)({
  //cancelled
  backgroundColor: red[500],
  color: "#fff",
});
const FalseStartChip = styled(Chip)({
  //falsestart
  backgroundColor: "#a4a4fd",
  color: "#fff",
});
const ActiveChip = styled(Chip)({
  //active
  backgroundColor: "#51b49a",
  color: "#fff",
});
const CD = styled(MuiCard)(spacing);
const Card = styled(CD)`
  cursor: pointer;
`;

const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};

const FilterGrid = styled(Grid)`
  background-color: #efefef;
  border-radius: 5px;
`;

const WhiteGrid = styled(Grid)`
  background-color: #fff;
  border-radius: 5px;
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: 10px;
    width: 100%;
  }
`;

function FilterDialog() {
  const [open, setOpen] = React.useState(false);
  const [messageOnSuspend, setSuspendMessage] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFilterApply = () => {};
  const handleClose = () => {
    setOpen(false);
    setSuspendMessage(false);
  };

  const handleSuspend = () => {
    setOpen(false);
    setSuspendMessage(true);
  };
  const [value, setValue] = React.useState<Date | null>(null);
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { gilad, jason, antoine } = state;

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="warning"
        aria-haspopup="true"
        fullWidth={true}
      >
        {stringData.filter.filter}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Search Filters"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">Country</StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the country from the list.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">City</StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the city from the list.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">Login Dates</StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <DatePicker
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">SOS Dates</StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <DatePicker
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Security Suites
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the security suites used by the user.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">Language</StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the language by user's phone.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">Gender</StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the gender.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid container xs={12} alignItems="center">
            <Typography variant="body2" color={grey[500]}>
              Include suspended accounts:
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="warning"
                  checked={antoine}
                  onChange={handleChange}
                  name="antoine"
                />
              }
              label=""
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            style={{ display: "flex", justifyContent: "flex-end" }}
            px={6}
          >
            <Button onClick={handleSuspend} variant="contained" color="warning">
              Clear Filter
            </Button>
            &nbsp; &nbsp;
            <Button onClick={handleFilterApply} variant="outlined">
              Apply & Show Results
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={messageOnSuspend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Filter has been applied successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SOS() {
  interface Sos {
    id: String;
    sosId: Number;
    userId: String;
    userDisplayName: String;
    created: String;
    audioId: String;
    lastUpdate: String;
    lastUpdateTimeStamp: Number;
    status: String;
    clientIdPublished: String;
    cancellationRequest: {};
    conversationId: Number;
  }

  const { t } = useTranslation();
  const [viewDetail, setOpen] = React.useState("none");
  const handleClickOpen = () => {
    setOpen("block");
  };

  const handleClose = () => {
    setOpen("none");
    setAnchorEl(null);
    setAnchorE(null);
  };
  const [Loading, setLoading] = React.useState(false);
  const [SosData, setSosData] = React.useState([]);
  const [DefaultSosData, setDefaultSosData] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    DsiplaySos();
  }, []);

  const DsiplaySos = () => {
    const timeStamp = Math.floor(Date.now() / 1000);

    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/list?timestamp=${timeStamp}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setLoading(false);
            setSosData(res.data);
            setDefaultSosData(res.data);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [SearchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [SosDetail, setSosDetail] = useState([] as any);
  const [ViewDetail, setViewDetail] = useState(false);
  const [Clicked, setClicked] = useState(false);

  const GetSosDetails = (id: String) => {
    setViewDetail(false);
    setClicked(true);

    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/Sos?sosGuid=${id}`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res: any) => {
            setSosDetail(res.data);
            setViewDetail(true);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultSosData.filter((sos: Sos) =>
        sos.userDisplayName.toLowerCase().includes(term.toLowerCase())
      );
      setSosData(results);
    } else {
      setSosData(DefaultSosData);
    }
    setSearchTerm(term);
  };

  const [SortBy, setSortBy] = useState({ name: -1, userId: -1, sos: "All" });

  const [sortByNameType, setsortByNameType] = useState(1);
  const handleSortByName = (type: number) => {
    if (type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a.userDisplayName.toLowerCase()[0] <
          b.userDisplayName.toLowerCase()[0]
          ? -1
          : 1;
      });
      setSosData(newdata);
      setsortByNameType(0);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a.userDisplayName.toLowerCase()[0] <
          b.userDisplayName.toLowerCase()[0]
          ? 1
          : -1;
      });
      setSosData(newdata);
      setsortByNameType(1);
    }

    handleClose();
  };

  //Sort the sos list by user id.
  const [sortBySosIdType, setsortBySosIdType] = useState(1);
  const handleSortBySosId = (type: number) => {
    if (type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a.sosId - b.sosId;
      });
      setSosData(newdata);
      setsortBySosIdType(0);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return b.sosId - a.sosId;
      });
      setSosData(newdata);
      setsortBySosIdType(1);
    }

    handleClose();
  };

  //Sort the sos list by user id.
  const [sortByIdType, setsortByIdType] = useState(1);
  const handleSortById = (type: number) => {
    if (type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a.userId - b.userId;
      });
      setSosData(newdata);
      setsortByIdType(0);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return b.userId - a.userId;
      });
      setSosData(newdata);
      setsortByIdType(1);
    }

    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE, setAnchorE] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE(event.currentTarget);
  };

  const SosCard = (key: Sos) => {
    const created_date = new Date(String(key.created));
    return (
      <Grid item xs={12} md={12} p={0} onClick={() => GetSosDetails(key.id)}>
        <Card mb={2}>
          <CardContent
            onClick={handleClickOpen}
            sx={{ padding: "8px !important" }}
          >
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={3}
                alignItems="center"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <BigAvatar
                  alt={String(key.userDisplayName)}
                  src={String(key.userDisplayName)}
                />{" "}
                <Typography variant="body2" color={grey[500]} pl={2}>
                  {key.userDisplayName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {key.userId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {key.sosId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {`${created_date.getFullYear()}-${created_date.getMonth()}-${created_date.getDate()}`}{" "}
                  {`${created_date.getHours()}:${created_date.getMinutes()}`}{" "}
                  {created_date.getHours() > 11 ? "PM" : "AM"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {key.status == "Active" ? (
                  <ActiveChip
                    size="small"
                    label={stringData.filter.active}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
                {key.status == "PendingCancel" ? (
                  <PendingChip
                    size="small"
                    label={stringData.filter.pendingCancel}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
                {key.status == "Cancelled" ? (
                  <CancelledChip
                    size="small"
                    label={stringData.filter.cancelled}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
                {key.status == "FalseStart" ? (
                  <FalseStartChip
                    size="small"
                    label={stringData.filter.falseStart}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <React.Fragment>
      <Helmet title="SOS Alerts" />
      <Grid container>
        <Grid item xs={6} pt={2}>
          <Grid container xs={12} pb="10px" spacing={2}>
            <FilterGrid container xs={7}>
              <Grid
                item
                xs={10}
                alignItems="center"
                style={{ display: "flex", justifyItems: "flex-start" }}
                pl={2}
              >
                {" "}
                <SearchIcon color={"disabled"} />
                <Input
                  placeholder={stringData.inputPlaceholder.sosSearch}
                  onChange={handleChange}
                  value={SearchTerm}
                  sx={{ paddingLeft: "10pxs" }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                alignItems="center"
                style={{ display: "flex", justifyContent: "right" }}
                pr={2}
              >
                {" "}
                <Clear
                  color={"disabled"}
                  style={{ cursor: "pointer" }}
                  onClick={() => (
                    setSosData(DefaultSosData), setSearchTerm("")
                  )}
                />
              </Grid>
            </FilterGrid>
            <Grid item xs={1}></Grid>

            <WhiteGrid item xs={4}>
              <Actions
                data={SosData}
                callback={setSosData}
                defaultData={DefaultSosData}
                sortcallback={setSortBy}
              />
            </WhiteGrid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={12} md={12} p={0}>
              <Grid
                container
                style={{ display: "flex", alignItems: "center" }}
                p={2}
              >
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSortByName(sortByNameType)}
                >
                  <Typography
                    variant="body2"
                    color={grey[800]}
                    alignItems="center"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {stringData.tableHeader.name}{" "}
                    {sortByNameType ? (
                      <Ascending></Ascending>
                    ) : (
                      <Descending></Descending>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSortById(sortByIdType)}
                >
                  <Typography
                    variant="body2"
                    color={grey[800]}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {stringData.tableHeader.userId}{" "}
                    {sortByIdType ? (
                      <Ascending></Ascending>
                    ) : (
                      <Descending></Descending>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  xs={2}
                  onClick={() => handleSortBySosId(sortBySosIdType)}
                >
                  {stringData.tableHeader.sosId}{" "}
                  {sortBySosIdType ? (
                    <Ascending></Ascending>
                  ) : (
                    <Descending></Descending>
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="body2" color={grey[800]}>
                    {stringData.tableHeader.createdDate}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="body2" color={grey[800]}>
                    {stringData.tableHeader.sosStatus}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            {Loading ? (
              <Grid
                item
                xs={8}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                alignItems="center"
              >
                <CircularProgress color="warning" size={30} />
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          <Grid container xs={12}>
            <Grid
              container
              xs={12}
              direction="row"
              mt={0}
              sx={{
                maxHeight: "72vh",
                overflowY: "scroll",
                position: "relative",
              }}
            >
              {SosData.map(SosCard)}
              <Grid container xs={12} justifyContent="center" py={3}>
                {Loading ? (
                  <></>
                ) : (
                  <Grid
                    item
                    xs={8}
                    sm={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                    alignItems="center"
                  >
                    <Button disabled={true} variant="outlined">
                      {stringData.button.noMoreData}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} pl={5} pt={0}>
          {ViewDetail ? (
            <SosUserDetails data={SosDetail} viewAudio={false}></SosUserDetails>
          ) : (
            <></>
          )}
          {Clicked ? (
            <></>
          ) : (
            <Card sx={{ width: "100%" }} pt={0}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                  p={5}
                >
                  <CardContent>
                    <Typography variant="h5" color={grey[500]}>
                      {stringData.info.sosViewDetail}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SOS;
