import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, yellow, red, green, purple } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import { ClosedCaption } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import stringData from "../stringFile";

import "react-h5-audio-player/lib/styles.css";
import {
  Avatar,
  Button,
  CardContent,
  Chip,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  TableCell,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  FiberManualRecord as StartIcon,
  Stop as StopIcon,
  LocationOn,
  LocationOff,
  Mic as MicOn,
  MicOff as MicOff,
  NotificationsActive as NotificationOn,
  NotificationsOff as NotificationOff,
  Battery20,
  Battery50,
  Battery60,
  Battery90,
  Battery30,
  BatteryFull,
  BatteryUnknown,
  OpenInNew,
} from "@material-ui/icons";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;

const PendingChip = styled(Chip)({
  //pending cancel
  backgroundColor: "#e5a83a",
  color: "#fff",
});
const CancelledChip = styled(Chip)({
  //cancelled
  backgroundColor: red[500],
  color: "#fff",
});
const FalseStartChip = styled(Chip)({
  //falsestart
  backgroundColor: "#a4a4fd",
  color: "#fff",
});
const ActiveChip = styled(Chip)({
  //active
  backgroundColor: "#51b49a",
  color: "#fff",
});
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function SosUserDetails(props: any) {
  const { t } = useTranslation();
  const [viewDetail, setOpen] = React.useState("none");

  const handleClickOpen = () => {
    setOpen("block");
  };

  const handleClose = () => {
    setOpen("none");
  };
  const srcSet = [
    "https://converter-audio-example-1.s3.eu-central-1.amazonaws.com/Russell%2C%2BMale%2B-%2BEnglish%2C%2BAustralian+(1)+(online-audio-converter.com).wav",
    "https://converter-audio-examples.s3.eu-central-1.amazonaws.com/Russell%2C+Male+-+English%2C+Australian.mp3",
  ];
  const [SosDetail, setSosDetail] = React.useState([] as any);
  const [Loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSosDetail(props.data);
    GetSosAudio(props.data.audioId);
  }, []);

  const id = props.data.sosid;

  const GetSosDetails = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/Sos?sosGuid=${id}`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res: any) => {
            setLoading(false);
            setSosDetail(res.data);
            GetSosAudio(props.data.audioId);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [Audios, setAudios] = React.useState([] as any);
  const [AudioLoaded, setAudioLoaded] = React.useState(false);

  const GetSosAudio = (audioId: any) => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/audio?audioId=${audioId}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res: any) => {
            setLoading(false);
            setAudios(res.data.files);
            setAudioLoaded(true);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const AudioList = (key: any) => {
    return <AudioPlayer src={key} style={{ borderRadius: 10, margin: 10 }} />;
  };

  const lastUpdate = new Date(String(SosDetail.lastUpdate));
  const startTime = new Date(String(SosDetail.created));

  return (
    <Card sx={{ width: "100%" }} pt={0}>
      <CardContent>
        <Grid container direction="row" mb={5}>
          <Grid item xs={2} alignItems="center">
            <ExtraBigAvatar
              alt={SosDetail.userDisplayName}
              src={SosDetail.userDisplayName}
            />
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "left",
            }}
            direction="column"
            px={10}
          >
            <Typography variant="h6">{SosDetail.userDisplayName}</Typography>
            <Typography variant="body2" color={grey[500]} gutterBottom>
              {stringData.tableHeader.userId}: {SosDetail.userId}
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
            {SosDetail.status == "Active" ? (
              <ActiveChip size="small" label={stringData.filter.active} />
            ) : (
              ""
            )}
            {SosDetail.status == "PendingCancel" ? (
              <PendingChip
                size="small"
                label={stringData.filter.pendingCancel}
              />
            ) : (
              ""
            )}
            {SosDetail.status == "Cancelled" ? (
              <CancelledChip size="small" label={stringData.filter.cancelled} />
            ) : (
              ""
            )}
            {SosDetail.status == "FalseStart" ? (
              <FalseStartChip
                size="small"
                label={stringData.filter.falseStart}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <StartIcon color="warning" />
          </Grid>
          <Grid item xs={3}>
            {stringData.sosDetails.startTime}:
          </Grid>
          <Grid item xs={6}>
            {`${startTime.getFullYear()}-${startTime.getMonth()}-${startTime.getDate()}`}{" "}
            {`${startTime.getHours()}:${startTime.getMinutes()}`}{" "}
            {startTime.getHours() > 11 ? "PM" : "AM"}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <StopIcon color="warning" />
          </Grid>
          <Grid item xs={3}>
            {stringData.sosDetails.lastOnline}:
          </Grid>
          <Grid item xs={6}>
            {`${lastUpdate.getFullYear()}-${lastUpdate.getMonth()}-${lastUpdate.getDate()}`}{" "}
            {`${lastUpdate.getHours()}:${lastUpdate.getMinutes()}`}{" "}
            {lastUpdate.getHours() > 11 ? "PM" : "AM"}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <LocationOn color="warning" />
          </Grid>
          <Grid item xs={3}>
            {stringData.sosDetails.sharedLocation}:
          </Grid>
          <Grid item xs={6}>
            4 Glastone Pde, Boradmeadows, 3046
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          {SosDetail.initialBattery > 50 ? (
            SosDetail.initialBattery > 90 ? (
              <Battery90 color="warning" />
            ) : SosDetail.initialBattery > 60 ? (
              <Battery60 color="warning" />
            ) : SosDetail.initialBattery > 50 ? (
              <Battery50 color="warning" />
            ) : (
              <Battery50 color="warning" />
            )
          ) : SosDetail.initialBattery <= 20 ? (
            <Battery20 color="warning" />
          ) : SosDetail.initialBattery < 50 ? (
            <Battery30 color="warning" />
          ) : (
            <Battery50 color="warning" />
          )}

          {SosDetail.notificationsEnabled ? (
            <NotificationOn color="warning" />
          ) : (
            <NotificationOff color="disabled" />
          )}
          {SosDetail.micEnabled ? (
            <MicOn color="warning" />
          ) : (
            <MicOff color="disabled" />
          )}
          {SosDetail.locationEnabled ? (
            <LocationOn color="warning" />
          ) : (
            <LocationOff color="disabled" />
          )}
        </Grid>
        {props.viewAudio ? <Divider my={6} /> : <></>}

        {props.viewAudio ? (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" mb={2} color={grey[800]}>
                {stringData.sosDetails.sharedVoicemails}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ maxHeight: 200, overflow: "scroll", paddingRight: 20 }}
            >
              {AudioLoaded && Audios.map(AudioList)}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Link
                href={"/sos/details/" + SosDetail.id}
                underline="none"
                target="_blank"
              >
                <Button variant="outlined" color="warning">
                  {stringData.button.viewDetails}
                  <OpenInNew
                    fontSize="small"
                    style={{ paddingLeft: "5px" }}
                  ></OpenInNew>
                </Button>
              </Link>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default SosUserDetails;
