import React, { useState } from "react";
import styled from "styled-components/macro";

import { Button as MuiButton, Menu, MenuItem, Grid } from "@material-ui/core";
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import id from "date-fns/esm/locale/id/index.js";
import { grey, yellow, red, green, purple } from "@material-ui/core/colors";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
  FilterList as FilterIcon,
} from "@material-ui/icons";
import axios from "axios";
const Button = styled(MuiButton)({
  backgroundColor: "white",
});

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions(props: any) {
  interface User {
    accesss: String;
    companyId: String;
    email: String;
    id: String;
    isLockoutEnabled: Boolean;
    name: String;
    roles: [];
    userName: String;
    zoneIds: [];
    phone: String;
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE, setAnchorE] = React.useState<null | HTMLElement>(null);
  const [selectedSoSView, setselectedSoSView] = useState("All");
  React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE(null);
  };

  const displayByStatus = (stat: string) => {
    let data = props.defaultData;
    if (stat == "All") {
      props.callback(data);
    } else {
      let newdata = data.filter((s: any) => s.status == stat);
      props.callback(newdata);
    }
    handleClose();
    setselectedSoSView(stat);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} pl={1}>
          <Button
            aria-owns={anchorE ? "sos-status" : undefined}
            aria-haspopup="true"
            onClick={handleClick1}
            fullWidth={true}
          >
            <Grid
              item
              xs={10}
              pl={1}
              color={grey[800]}
              alignItems="left"
              style={{ display: "flex", alignItems: "left" }}
            >
              <FilterIcon style={{ paddingRight: "5px" }}></FilterIcon> {stringData.dUsers.view}:{" "}
              {selectedSoSView}
            </Grid>
            <Grid
              item
              xs={2}
              pl={1}
              color={grey[800]}
              alignItems="center"
              style={{ display: "flex", alignItems: "center" }}
            >
              {anchorE ? <Ascending></Ascending> : <Descending></Descending>}
            </Grid>
          </Button>
          <Menu
            id="sos-status"
            anchorEl={anchorE}
            open={Boolean(anchorE)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => displayByStatus("All")}>{stringData.filter.all}</MenuItem>
            <MenuItem onClick={() => displayByStatus("Active")}>
            {stringData.filter.active}
            </MenuItem>
            <MenuItem onClick={() => displayByStatus("PendingCancel")}>
            {stringData.filter.pendingCancel}
            </MenuItem>
            <MenuItem onClick={() => displayByStatus("Cancelled")}>
            {stringData.filter.cancelled}
            </MenuItem>
            <MenuItem onClick={() => displayByStatus("FalseStart")}>
            {stringData.filter.falseStart}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Actions;
