import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Dialog,
  Chip,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Grid,
} from "@material-ui/core";
import { display, spacing } from "@material-ui/system";
import getAuthorizationToken from "../../api/GetAuthorizationToken";
import useAuth from "../../hooks/useAuth";
import stringData from "../../pages/stringFile";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const styledButton = styled(Button)`
  background: #333e48;
`;

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [Loading, setLoading] = React.useState(false);
  const [messageOnChange, setChangeMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClose = () => {
    setChangeMessage(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .max(255)
            .required(stringData.login.usernameRequired),
          password: Yup.string()
            .max(255)
            .required(stringData.login.passwordRequired),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setLoading(true);
          const data = {
            username: values.username,
            password: values.password,
            platform: 1,
          };
          try {
            if (data?.username == "jerry" && data?.password == "!@##@!") {
              const newData = { auth_token: data?.password };
              localStorage.setItem("auth_session", JSON.stringify(newData));
              localStorage.setItem("leelou_aun", data?.username);
              navigate("/");
            } else {
              setLoading(false);
              setMessage(stringData.login.incorrectCredentials);
              setChangeMessage(true);
            }
            // axios
            //   .post(
            //     `https://vfolfkzmpc.execute-api.ap-southeast-2.amazonaws.com/prod/api/auth/token`,
            //     data
            //   )
            //   .then((response) => {
            //     const auth_data = response.data ? response.data : false;
            //     if (auth_data) {
            //       localStorage.setItem(
            //         "auth_session",
            //         JSON.stringify(auth_data)
            //       );
            //       localStorage.setItem("leelou_aun", values.username);
            //       navigate("/");
            //     } else {
            //       setMessage(stringData.login.incorrectCredentials);
            //       setChangeMessage(true);
            //     }
            //   })
            //   .catch((e) => {
            //     setLoading(false);

            //     setMessage(stringData.login.incorrectCredentials);
            //     setChangeMessage(true);
            //   });
          } catch (error: any) {
            const message = error.message || "Failed to authenticate";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="text"
              name="username"
              label={stringData.login.username}
              value={values.username}
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              variant="standard"
              color="warning"
              sx={{ background: "rgba(0,0,0,0) !important" }}
            />
            <TextField
              type="password"
              name="password"
              label={stringData.login.password}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
              variant="standard"
              color="warning"
              sx={{ background: "rgba(0,0,0,0) !important" }}
            />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="flex-start"
            >
              <Grid item>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={stringData.login.rememberme}
                />
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to="/auth/reset-password"
                  fullWidth
                  color="primary"
                >
                  {stringData.login.forgotPassword}
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="flex-start"
              spacing={5}
            >
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                >
                  {Loading ? (
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CircularProgress color="secondary" size={30} />
                    </Grid>
                  ) : (
                    stringData.login.signIn
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                >
                  {stringData.login.signUp}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Dialog
        open={messageOnChange}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignIn;
