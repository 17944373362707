import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import axios from "axios";
import AlertDialog from "./AlertDialog";
import { grey, yellow, purple } from "@material-ui/core/colors";
import stringData from "../stringFile";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
} from "@material-ui/icons";
import { is } from "immer/dist/internal";
import { BASE_URL } from "../../api/ApiEndPoints";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const SmallAvatar = styled(Avatar)`
  width: 25px;
  height: 25px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;
const PendingChip = styled(Chip)({
  //pending cancel
  backgroundColor: yellow[100],
});
const CancelledChip = styled(Chip)({
  //cancelled
  backgroundColor: red[100],
});
const FalseStartChip = styled(Chip)({
  //falsestart
  backgroundColor: purple[100],
});
const ActiveChip = styled(Chip)({
  //active
  backgroundColor: green[100],
});

function UserNetworkTabs(props: any) {
  interface Network {
    contact_id: Number;
    create_date: String;
    displayname: String;
    invitation: String;
    last_update: String;
    relation: String;
    share_location: Number;
    user_a: Number;
    user_b: Number;
  }
  const UserDetail = props.data;
  const [value, setValue] = React.useState("1");
  const [NetworkData, setNetworkData] = useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const prevData = props.data;
  console.log(prevData);
  // useEffect(() => {
  //   UsersNetworkDetail();
  // }, [props]);
  const FixedWidthTableCell = styled(StyledTableCell)({
    width: "50%",
  });
  const [UserNetwork, setUserNetwork] = useState([] as any);
  const [RegisteretNetwork, setRegisteredNetwork] = useState([]);
  const [UnRegisteredNetwork, setUnRegisteredNetwork] = useState([]);

  const GetUserDetails = async () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    return await axios
      .get(`${BASE_URL}/api/LeelouUser/${prevData?.UserId}`, {
        headers: { Authorization: `Bearer ${auth_token.token}` },
      })
      .then((res) => {
        setUserNetwork(res.data.User);
        setRegisteredNetwork(res.data.Contacts);
        setUnRegisteredNetwork(res.data.UnregistedInvitedContacts);
        return res.data;
      });
  };

  const { data, isLoading } = useQuery({
    queryKey: ["get-user-details", prevData?.UserId],
    queryFn: () => GetUserDetails(),
  });

  const UsersNetworkDetail = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    if (auth_token !== "") {
      axios
        .get(
          `https://test-adminapi.meetleelou.com/api/LeelouUser/${props.data.UserId}`,
          { headers: { Authorization: `Bearer ${auth_token.token}` } }
        )
        .then((res) => {
          setUserNetwork(res.data.User);
          setRegisteredNetwork(res.data.Contacts);
          setUnRegisteredNetwork(res.data.UnregistedInvitedContacts);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  type codeopt = {
    [key: string]: string;
  };
  const phone_code: codeopt = {
    BD: "880",
    BE: "32",
    BF: "226",
    BG: "359",
    BA: "387",
    BB: "+1-246",
    WF: "681",
    BL: "590",
    BM: "+1-441",
    BN: "673",
    BO: "591",
    BH: "973",
    BI: "257",
    BJ: "229",
    BT: "975",
    JM: "+1-876",
    BV: "",
    BW: "267",
    WS: "685",
    BQ: "599",
    BR: "55",
    BS: "+1-242",
    JE: "+44-1534",
    BY: "375",
    BZ: "501",
    RU: "7",
    RW: "250",
    RS: "381",
    TL: "670",
    RE: "262",
    TM: "993",
    TJ: "992",
    RO: "40",
    TK: "690",
    GW: "245",
    GU: "+1-671",
    GT: "502",
    GS: "",
    GR: "30",
    GQ: "240",
    GP: "590",
    JP: "81",
    GY: "592",
    GG: "+44-1481",
    GF: "594",
    GE: "995",
    GD: "+1-473",
    GB: "44",
    GA: "241",
    SV: "503",
    GN: "224",
    GM: "220",
    GL: "299",
    GI: "350",
    GH: "233",
    OM: "968",
    TN: "216",
    JO: "962",
    HR: "385",
    HT: "509",
    HU: "36",
    HK: "852",
    HN: "504",
    HM: " ",
    VE: "58",
    PR: "+1-787 and 1-939",
    PS: "970",
    PW: "680",
    PT: "351",
    SJ: "47",
    PY: "595",
    IQ: "964",
    PA: "507",
    PF: "689",
    PG: "675",
    PE: "51",
    PK: "92",
    PH: "63",
    PN: "870",
    PL: "48",
    PM: "508",
    ZM: "260",
    EH: "212",
    EE: "372",
    EG: "20",
    ZA: "27",
    EC: "593",
    IT: "39",
    VN: "84",
    SB: "677",
    ET: "251",
    SO: "252",
    ZW: "263",
    SA: "966",
    ES: "34",
    ER: "291",
    ME: "382",
    MD: "373",
    MG: "261",
    MF: "590",
    MA: "212",
    MC: "377",
    UZ: "998",
    MM: "95",
    ML: "223",
    MO: "853",
    MN: "976",
    MH: "692",
    MK: "389",
    MU: "230",
    MT: "356",
    MW: "265",
    MV: "960",
    MQ: "596",
    MP: "+1-670",
    MS: "+1-664",
    MR: "222",
    IM: "+44-1624",
    UG: "256",
    TZ: "255",
    MY: "60",
    MX: "52",
    IL: "972",
    FR: "33",
    IO: "246",
    SH: "290",
    FI: "358",
    FJ: "679",
    FK: "500",
    FM: "691",
    FO: "298",
    NI: "505",
    NL: "31",
    NO: "47",
    NA: "264",
    VU: "678",
    NC: "687",
    NE: "227",
    NF: "672",
    NG: "234",
    NZ: "64",
    NP: "977",
    NR: "674",
    NU: "683",
    CK: "682",
    XK: "",
    CI: "225",
    CH: "41",
    CO: "57",
    CN: "86",
    CM: "237",
    CL: "56",
    CC: "61",
    CA: "1",
    CG: "242",
    CF: "236",
    CD: "243",
    CZ: "420",
    CY: "357",
    CX: "61",
    CR: "506",
    CW: "599",
    CV: "238",
    CU: "53",
    SZ: "268",
    SY: "963",
    SX: "599",
    KG: "996",
    KE: "254",
    SS: "211",
    SR: "597",
    KI: "686",
    KH: "855",
    KN: "+1-869",
    KM: "269",
    ST: "239",
    SK: "421",
    KR: "82",
    SI: "386",
    KP: "850",
    KW: "965",
    SN: "221",
    SM: "378",
    SL: "232",
    SC: "248",
    KZ: "7",
    KY: "+1-345",
    SG: "65",
    SE: "46",
    SD: "249",
    DO: "+1-809 and 1-829",
    DM: "+1-767",
    DJ: "253",
    DK: "45",
    VG: "+1-284",
    DE: "49",
    YE: "967",
    DZ: "213",
    US: "1",
    UY: "598",
    YT: "262",
    UM: "1",
    LB: "961",
    LC: "+1-758",
    LA: "856",
    TV: "688",
    TW: "886",
    TT: "+1-868",
    TR: "90",
    LK: "94",
    LI: "423",
    LV: "371",
    TO: "676",
    LT: "370",
    LU: "352",
    LR: "231",
    LS: "266",
    TH: "66",
    TF: "",
    TG: "228",
    TD: "235",
    TC: "+1-649",
    LY: "218",
    VA: "379",
    VC: "+1-784",
    AE: "971",
    AD: "376",
    AG: "+1-268",
    AF: "93",
    AI: "+1-264",
    VI: "+1-340",
    IS: "354",
    IR: "98",
    AM: "374",
    AL: "355",
    AO: "244",
    AQ: "",
    AS: "+1-684",
    AR: "54",
    AU: "61",
    AT: "43",
    AW: "297",
    IN: "91",
    AX: "+358-18",
    AZ: "994",
    IE: "353",
    ID: "62",
    UA: "380",
    QA: "974",
    MZ: "258",
  };

  const get_country = (code: string) => {
    return Object.keys(phone_code).find((key) => phone_code[key] === code);
  };

  const GuardianNetworkCard = (key: Network) => {
    if (key.relation == "Guardian") {
      return (
        <TableRow>
          <TableCell align="right" style={{ padding: "4px" }}>
            <SmallAvatar
              alt={UserDetail.displayname}
              src={UserDetail.displayname}
            />
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2">{key.displayname}</Typography>
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2" color={grey[500]}>
              {key.user_b
                ? stringData.lUsers.userId + ": "
                : stringData.lUsers.phone + ": "}
              {/* {key.user_b ? key.user_b : key.country + "-" + key.number} */}
            </Typography>
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? "" : <Pending color="warning" />}
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? <Approved color="warning" /> : ""}
          </TableCell>
        </TableRow>
      );
    }
  };

  const getUserSos = (key: any) => {
    const created_date = new Date(String(key.created));

    const uid = props.UserDetail.UserId;
    if (key.UserId == uid) {
      return (
        <Card mb={2}>
          <CardContent sx={{ padding: "5px !important" }}>
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {`${created_date.getFullYear()}-${created_date.getMonth()}-${created_date.getDate()}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {`${created_date.getHours()}:${created_date.getMinutes()}`}{" "}
                  {created_date.getHours() > 11 ? "PM" : "AM"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {key.status == "Active" ? (
                  <ActiveChip size="small" label={stringData.filter.active} />
                ) : (
                  ""
                )}
                {key.status == "PendingCancel" ? (
                  <PendingChip
                    size="small"
                    label={stringData.filter.pendingCancel}
                  />
                ) : (
                  ""
                )}
                {key.status == "Cancelled" ? (
                  <CancelledChip
                    size="small"
                    label={stringData.filter.cancelled}
                  />
                ) : (
                  ""
                )}
                {key.status == "FalseStart" ? (
                  <FalseStartChip
                    size="small"
                    label={stringData.filter.falseStart}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  };
  const FriendNetworkCard = (key: Network) => {
    if (key.relation == "Friend") {
      return (
        <TableRow>
          <TableCell align="right" style={{ padding: "4px" }}>
            <SmallAvatar
              alt={UserDetail.displayname}
              src={UserDetail.displayname}
            />
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2">{key.displayname}</Typography>
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2" color={grey[500]}>
              {key.user_b ? "User Id: " : "Phone: "}
              {/* {key.user_b ? key.user_b : key.country + "-" + key.number} */}
            </Typography>
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? "" : <Pending color="warning" />}
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? <Approved color="warning" /> : ""}
          </TableCell>
        </TableRow>
      );
    }
  };
  const DependentNetworkCard = (key: Network) => {
    if (key.relation == "Dependent") {
      return (
        <TableRow>
          <TableCell align="right" style={{ padding: "4px" }}>
            <SmallAvatar
              alt={UserDetail.displayname}
              src={UserDetail.displayname}
            />
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2">{key.displayname}</Typography>
          </TableCell>
          <TableCell align="left" style={{ padding: "4px" }}>
            <Typography variant="body2" color={grey[500]}>
              {key.user_b ? "User Id: " : "Phone: "}
              {/* {key.user_b ? key.user_b : key.country + "-" + key.number} */}
            </Typography>
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? "" : <Pending color="warning" />}
          </TableCell>
          <TableCell align="center" style={{ padding: "4px" }}>
            {key.user_b ? <Approved color="warning" /> : ""}
          </TableCell>
        </TableRow>
      );
    }
  };

  //{RegisteretNetwork.map(RegisteredContact)}
  const created_date = new Date(String(UserNetwork.create_date));
  const last_online = new Date(String(UserNetwork.last_update));
  const birthday = new Date(String(UserNetwork.birthday));

  const [OpenUserSos, setOpenUserSos] = useState(false);
  const handleClose = () => {
    setOpenUserSos(false);
  };

  return (
    <>
      <Card>
        <CardContent style={{ paddingBottom: "0px !important" }}>
          <Grid container>
            <Grid item xs={2} alignItems="center">
              <ExtraBigAvatar
                alt={prevData?.FirstName}
                src={prevData?.FirstName}
              />
            </Grid>
            <Grid
              item
              xs={8}
              style={{ display: "flex", justifyContent: "center" }}
              direction="column"
              px={5}
            >
              <Typography variant="h6">
                {prevData?.DisplayName}
                <Chip
                  size="small"
                  style={{ marginLeft: "5px" }}
                  label={prevData?.DeviceType}
                />
              </Typography>
              {/* <Typography variant="body2" color={grey[500]} gutterBottom>
                User Id: {UserDetail.UserId}
              </Typography> */}
              <Grid item>
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  onClick={() => {
                    setOpenUserSos(true);
                  }}
                >
                  {stringData.lUsers.sosHistory}
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AlertDialog />
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    <DateIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.dob}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {`${birthday.getFullYear()}-${
                      birthday.getMonth() + 1
                    }-${birthday.getDate()}`}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <GenderIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.gender}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {UserNetwork?.gender}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <DateIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Registered Date
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {`${created_date.getFullYear()}-${
                      created_date.getMonth() + 1
                    }-${created_date.getDate()}`}{" "}
                    {`${created_date.getHours()}:${created_date.getMinutes()}`}{" "}
                    {created_date.getHours() > 11 ? "PM" : "AM"}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <LocationIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.country}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {UserNetwork.mobileCountryCode == "61"
                      ? "Australia"
                      : get_country(UserDetail.mobileCountryCode)}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <EmailIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.email}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {prevData?.Email}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <PhoneIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.phone}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    ({prevData?.MobileCountryCode}) -{prevData?.MobileNumber}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <DateIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.lUsers.lastLogin}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {`${last_online.getFullYear()}-${
                      last_online.getMonth() + 1
                    }-${last_online.getDate()}`}{" "}
                    {`${last_online.getHours()}:${last_online.getMinutes()}`}{" "}
                    {last_online.getHours() > 11 ? "PM" : "AM"}
                  </FixedWidthTableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Grid
              item
              xs={12}
              mt={3}
              alignItems="right"
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card mt={3}>
        <CardContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Guardians" value="1" style={{ padding: "3px" }} />
                  <Tab label="Friends" value="2" style={{ padding: "3px" }} />
                  <Tab
                    label="Dependents"
                    value="3"
                    style={{ padding: "3px" }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Table>
                  <TableHead>
                    <TableRow style={{ padding: "0px" }}>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.pending}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.approved}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {RegisteretNetwork.map(GuardianNetworkCard)}
                    {UnRegisteredNetwork.map(GuardianNetworkCard)}
                  </TableBody>
                </Table>
              </TabPanel>
              <TabPanel value="2">
                <Table>
                  <TableHead>
                    <TableRow style={{ padding: "0px" }}>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.pending}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.approved}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {RegisteretNetwork.map(FriendNetworkCard)}{" "}
                    {UnRegisteredNetwork.map(FriendNetworkCard)}
                  </TableBody>
                </Table>
              </TabPanel>
              <TabPanel value="3">
                <Table>
                  <TableHead>
                    <TableRow style={{ padding: "0px" }}>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        style={{ padding: "2px" }}
                      ></TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.pending}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "2px" }}>
                        {stringData.lUsers.approved}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {RegisteretNetwork.map(DependentNetworkCard)}{" "}
                    {UnRegisteredNetwork.map(DependentNetworkCard)}
                  </TableBody>
                </Table>
              </TabPanel>
            </TabContext>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={OpenUserSos}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "400px !important" }}
      >
        <DialogTitle id="alert-dialog-title">{"SoS History"}</DialogTitle>
        <DialogContent>{props.SoS.map(getUserSos)}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="warning"
            autoFocus
          >
            {stringData.button.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserNetworkTabs;
