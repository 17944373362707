import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing, SpacingProps } from "@material-ui/system";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function CreateForm() {
  interface Admin {
    email: String;
    name: String;
    userName: String;
    phone: String;
    companyId: String;
    roles: [];
    //address:String;
    //country:String;
    //securitySuits: [];
    //profileImage:String;
    //image:String;
    isViewOnly: Boolean;
    zoneIds: String;
  }

  interface User {
    accesss: String;
    companyId: String;
    email: String;
    id: String;
    isLockoutEnabled: Boolean;
    name: String;
    roles: [];
    userName: String;
    zoneIds: [];
    phone: String;
  }
  const initial_values = {
    email: "",
    name: "",
    userName: "",
    phone: "",
    companyId: "",
    roles: [],
    //address:String;
    //country:String;
    //securitySuits: [];
    //profileImage:String;
    //image:String;
    isViewOnly: false,
    zoneIds: "",
  };
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const navigate = useNavigate();
  const [values, setValues] = useState(initial_values);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userDetail, setUserDetail] = useState({} as User);

  useEffect(() => {
    displayUserName();
  }, []);

  const handleClose = () => {
    setChangeMessage(false);
  };
  const displayUserName = () => {
    const authorization_token = localStorage.getItem("auth_session");
    const user_name = localStorage.getItem("leelou_aun");
    setUserName(user_name ? user_name : "");

    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/auth/users`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            //setAllUsers(res.data);
            Object.keys(res.data).map(function (key, index) {
              if (res.data[key].userName.toLowerCase() == user_name) {
                setUserId(res.data[key].id);
                setUserDetail(res.data[key]);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const handleUserNameChange = async (e: any) => {
    setUserName(e.target.value);
  };
  const [messageOnChange, setChangeMessage] = React.useState(false);
  const [message, setMessage] = useState("");

  const changeUserName = async () => {
    const authorization_token = localStorage.getItem("auth_session");
    const user_name = localStorage.getItem("leelou_aun");

    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        const data = {
          newUserName: userName.toLowerCase(),
          newEmail: userDetail.email,
          newPhone: userDetail.phone,
          newName: userDetail.name,
        };

        if (userName != "") {
          axios
            .post(
              `https://test-adminapi.meetleelou.com/api/auth/user/edit/${userId}`,
              data,
              { headers: { Authorization: `Bearer ${auth_token.token}` } }
            )
            .then((res) => {
              localStorage.setItem("leelou_aun", userName);
              setUserName(userName);
              setMessage("User name successfully changed.");
              setChangeMessage(true);
              displayUserName();
            })
            .catch((error) => {
              console.log(error);
              setMessage("Error occured while changing user name.");
              setChangeMessage(true);
            });
        } else {
          setMessage("User name cannot be empty.");
          setChangeMessage(true);
        }
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const handleInputChange = async (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldsPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleNewPassword = async (e: any) => {
    setNewPassword(e.target.value);
  };
  const handleOldPassword = async (e: any) => {
    setOldsPassword(e.target.value);
  };
  const handleConfirmPassword = async (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordChange = () => {
    if (newPassword != confirmPassword) {
      setMessage("New password & Confirm password do not match.");
      setChangeMessage(true);
    } else {
      const authorization_token = localStorage.getItem("auth_session");
      const user_name = localStorage.getItem("leelou_aun");

      if (authorization_token) {
        const auth_token = JSON.parse(
          authorization_token != null ? authorization_token : ""
        );

        if (auth_token !== "") {
          const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          };

          axios
            .post(
              `https://test-adminapi.meetleelou.com/api/auth/change-password`,
              data,
              { headers: { Authorization: `Bearer ${auth_token.token}` } }
            )
            .then((res) => {
              localStorage.setItem("leelou_aun", userName);
              setUserName(userName);
              setMessage("Password successfully changed.");
              setChangeMessage(true);
            })
            .catch((error) => {
              setMessage("Error occured while changing password.");
              setChangeMessage(true);
              console.log(error);
            });
        }
      } else {
        navigate("/auth/sign-in");
      }
    }
  };
  return (
    <div>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Change your username or password.
          </Typography>
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Change User Name</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <TextField
                    id="user_name"
                    label="User Name"
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    onChange={handleUserNameChange}
                    value={userName}
                    name="userName"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12} py={2} justifyContent="center">
                    <Button
                      size="large"
                      color="warning"
                      variant="contained"
                      onClick={changeUserName}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Change Password</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <TextField
                    id="old_password"
                    label="Old Password"
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    onChange={handleOldPassword}
                    value={oldPassword}
                    name="oldPassword"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="new_password"
                    label="New Password"
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    onChange={handleNewPassword}
                    value={newPassword}
                    name="newPassword"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="confirm_password"
                    label="Confirm Password"
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    onChange={handleConfirmPassword}
                    value={confirmPassword}
                    name="confirmPassword"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12} py={2} justifyContent="center">
                    <Button
                      size="large"
                      color="warning"
                      variant="contained"
                      onClick={handlePasswordChange}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>{" "}
        </CardContent>
      </Card>
      <Dialog
        open={messageOnChange}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Settings() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Grid container>
        <Grid item xs={6} md={6}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
