import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import stringData from "../stringFile";

import {
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Badge,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell as TC,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
  Call as CallIcon,
} from "@material-ui/icons";
const Spacer = styled.div(spacing);
const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const Card = styled(MuiCard)(spacing);
const TableCell = styled(TC)({
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "5px",
  paddingBottom: "5px",
});
function SosGuards(props: any) {
  const [SosDetail, setSosDetail] = React.useState([] as any);
  const [Loading, setLoading] = React.useState(false);
  const [Loaded, setLoaded] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setSosDetail(props.data);
    setLoaded(true);
  }, []);

  const id = props.data.sosid;

  const GetSosDetails = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/Sos?sosGuid=${id}`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res: any) => {
            setLoading(false);
            setSosDetail(res.data);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const GuardianList = (key: any) => {
    return (
      <TableRow>
        <TableCell align="left">
          <Avatar alt={key.userDisplayName} src={key.userDisplayName} />
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {key.userDisplayName}
          </Typography>
          <Typography variant="body2" gutterBottom color={grey[500]}>
            {key.status}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Button size="small">
            <Link href="tel:+15555551212" underline={"none"}>
              <CallIcon color="warning" />
            </Link>
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {Loaded && (
        <Card mb={1} sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {stringData.sosDetails.guardians}
            </Typography>

            <Spacer mb={3} />

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SosDetail.guardians.map(GuardianList)}
                {false && (
                  <TableRow>
                    <TableCell align="left">
                      <Online
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar alt={"DSDS"} src={"DSDS"} />
                      </Online>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h6" gutterBottom>
                        Gleb Guznetvos
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        color={grey[500]}
                      >
                        Offline
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default SosGuards;
