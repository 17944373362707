import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";

import {
  Avatar,
  Button,
  Box,
  CardContent,
  Grid,
  CircularProgress,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell as TC,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import { Refresh as RefreshIcon } from "@material-ui/icons";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);
const TableCell = styled(TC)({
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "5px",
  paddingBottom: "5px",
});
function SosInteractions(props: any) {
  const userId = props.uid;
  useEffect(() => {
    setLoading(true);
    GetInteractions();
  }, []);
  const navigate = useNavigate();

  const [Loading, setLoading] = useState(false);
  const [Interactions, setInteractions] = useState([]);

  const GetInteractions = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Interaction?id=${userId}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res: any) => {
            setLoading(false);
            setInteractions(res.data);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const InteractionsList = (key: any) => {
    const created_date = new Date(String(key.timestamp));

    return (
      <TableRow>
        <TableCell align="left">
          <Typography variant="body2">{key.user.name}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" color={grey[500]}>
            {key.details}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" color={grey[500]}>
            {`${created_date.getFullYear()}/${created_date.getMonth()}/${created_date.getDate()}`}{" "}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2" color={grey[500]}>
            {`${created_date.getHours()}:${created_date.getMinutes()}`}{" "}
            {created_date.getHours() > 11 ? "PM" : "AM"}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card mb={1} sx={{ width: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6">
              {stringData.sosDetails.interactions}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <RefreshIcon color="disabled"></RefreshIcon>
          </Grid>
        </Grid>

        <Spacer mb={3} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>

              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Interactions.map(InteractionsList)}
            <Grid container xs={12}>
              {Loading ? (
                <Grid
                  item
                  xs={8}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  alignItems="center"
                >
                  <CircularProgress color="warning" size={30} />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default SosInteractions;
