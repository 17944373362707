import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import GoogleMapReact, { Maps } from "google-map-react";
import {
  CardContent,
  CardHeader,
  Card as MuiCard,
  Typography,
  Grid,
  Avatar,
  Button,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Map } from "@material-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./marker.css";
import { Info } from "react-feather";
import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const GoogleMapReactWrapper = styled.div`
  height: 45vh;
  width: 100%;
`;

function SosMap(props: any) {
  const YOUR_GOOGLE_MAP_API_KEY = "AIzaSyCJel-xS3Dd7M_5bXYBJOt8wPa2IXbmYeE";
  const navigate = useNavigate();

  const data = props.data;
  const options = {
    center: {
      lat: data.lastLocation.lat,
      lng: data.lastLocation.lng,
    },
    zoom: 14,
  };

  useEffect(() => {
    getBreadCrumbs();
    setloading(false);
  }, []);

  const [markers, setMarkers] = useState([]);
  const [loading, setloading] = useState(true);

  const getBreadCrumbs = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/${props.data.id}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res: any) => {
            setMarkers(res.data);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const toogleStreetView = () => {
    console.log("street view enabled");
  };
  const Marker = (props: any) => {
    const { color, name, id } = props;
    return (
      <>
        <div
          className={props.current ? "current pin bounce" : "breadcrumbs"}
          style={{ backgroundColor: color, cursor: "pointer" }}
        ></div>
        {props.current ? <Grid className="tooltip">{props.name}</Grid> : <></>}
      </>
    );
  };

  const getMapOptions = (maps: Maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: true,
      panControl: true,
    };
  };
  const [markerGap, setMarkerGap] = useState(1);

  const renderMarkers = () => {
    return markers.map((dt: any, index) => {
      return index % markerGap == 0 ? (
        <Marker
          lat={dt.latitude}
          lng={dt.longitude}
          name={props.data.userDisplayName}
          color="darkorange"
        />
      ) : (
        <></>
      );
    });
  };
  const [anchorE, setAnchorE] = React.useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setAnchorE(null);
  };
  const [markerRangeChange, setMarkerRangeChange] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE(event.currentTarget);
  };
  const [selectedRange, setSelectedRange] = useState("5 SEC");

  const handleMarkerGapChange = (gap: number) => {
    setMarkerRangeChange(true);
    setMarkerGap(gap);
    setSelectedRange((5 * gap).toString() + " SEC");
    closeMenu();
    setMarkerRangeChange(false);
  };

  return (
    <Card p={0} sx={{ width: "100%" }}>
      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid
          item
          xs={9}
          style={{ display: "flex", justifyContent: "flex-start" }}
          padding={2}
          paddingLeft={4}
        >
          <Typography variant="h6">SOS Map</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            aria-owns={anchorE ? "marker-range" : undefined}
            aria-haspopup="true"
            onClick={openMenu}
            fullWidth={false}
            color="warning"
          >
            Marker Ranger: {selectedRange}
          </Button>
          <Menu
            id="marker-range"
            anchorEl={anchorE}
            open={Boolean(anchorE)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => handleMarkerGapChange(1)}>5 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(2)}>10 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(3)}>15 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(4)}>20 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(5)}>25 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(6)}>30 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(7)}>35 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(8)}>40 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(9)}>45 SEC</MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(10)}>
              50 SEC
            </MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(11)}>
              55 SEC
            </MenuItem>
            <MenuItem onClick={() => handleMarkerGapChange(12)}>
              60 SEC
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <CardContent style={{ padding: "0px" }}>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            <GoogleMapReactWrapper>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: "AIzaSyCJel-xS3Dd7M_5bXYBJOt8wPa2IXbmYeE",
                }}
                defaultCenter={options.center}
                defaultZoom={options.zoom}
              >
                <Marker
                  lat={data.lastLocation.lat}
                  lng={data.lastLocation.lng}
                  name={props.data.userDisplayName}
                  color="#138908f5"
                  current={true}
                  onClick={toogleStreetView}
                />
                {markerRangeChange ? <></> : renderMarkers()}
              </GoogleMapReact>
            </GoogleMapReactWrapper>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default SosMap;
