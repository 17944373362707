import React from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";
import { orange, green, red, grey } from "@material-ui/core/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Theme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const SuccessfulSOS = ({ theme }: ThemeProps<Theme>) => {
  const data = {
    labels: ["Success", "Failed"],
    datasets: [
      {
        data: [48, 52],
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "85%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Successful SOS"
        pb={0}
        mb={0}
      />
      <Grid container p={"16px"}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Typography>Total:</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography color={green[500]}>55,000</Typography>
        </Grid>
      </Grid>

      <CardContent>
        <Grid container>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-start" }}
            alignItems="flex-end"
          >
            <Typography>Total Leelou Call</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "center" }}
            alignItems="flex-end"
          >
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">48%</Typography>
                <Typography variant="caption">Success</Typography>
              </DoughnutInner>
              <Chart type="doughnut" data={data} options={options} />
            </ChartWrapper>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
            alignItems="flex-end"
          >
            <Typography>Police Call</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
            alignItems="flex-end"
          >
            <Typography color={grey[500]}>18,570</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
            alignItems="flex-end"
          >
            <Typography color={grey[500]}>31,430</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withTheme(SuccessfulSOS);
