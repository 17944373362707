import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Button as MuiButton,
  Box,
  CardContent,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
} from "@material-ui/core";
import Actions from "./Actions";
import orange from "material-ui/colors/orange";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
} from "@material-ui/icons";

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function Default() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    getFeedBack();
  }, []);
  const [SearchTerm, setSearchTerm] = useState("");

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultFeedback.filter((key: Feedbak) =>
        String(key.userId).toLowerCase().includes(term.toLowerCase())
      );
      setFeedback(results);
    } else {
      setFeedback(DefaultFeedback);
    }
    setSearchTerm(term);
  };

  const [Feedback, setFeedback] = useState([]);
  const [DefaultFeedback, setDefaultFeedback] = useState([]);

  const getFeedBack = () => {
    const authorization_token = localStorage.getItem("auth_session");

    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/UserFeedback`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setFeedback(res.data);
            setDefaultFeedback(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const getUsers = () => {
    const authorization_token = localStorage.getItem("auth_session");
    const user_name = localStorage.getItem("leelou_aun");

    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/UserFeedback`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            // setUsers(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  interface Feedbak {
    id: String;
    message: String;
    userName: String;
    userId: Number;
    timeStamp: Number;
  }
  const FeedbackCard = (key: Feedbak) => {
    const created_date = new Date(Number(key.timeStamp) * 1000);

    return (
      <Grid item xs={12} md={6} sm={6} lg={6}>
        <Card mb={6}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Gleb Guznetvos
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`${created_date.getDate()}-${created_date.getMonth()}-${created_date.getFullYear()}`}
                </Typography>
              </Grid>
              <Grid
                item
                style={{ display: "flex", justifyContent: "flex-end" }}
                xs={6}
                md={6}
                sm={6}
                lg={6}
              >
                <Fab
                  mx={2}
                  size="small"
                  style={{ backgroundColor: "#ff8300", color: "#FFFFFF" }}
                  aria-label="Favorite"
                >
                  <FavoriteIcon />
                </Fab>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom mt={3}>
                  {key.message}
                </Typography>
              </Grid>
              <Grid item mt={3} xs={12}>
                <StarRateIcon color="warning" />
                <StarRateIcon color="warning" />
                <StarRateIcon color="warning" />
                <StarRateIcon color="warning" />
                <StarHalfIcon color="warning" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">4.5 Rating</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <React.Fragment>
      <Helmet title="Feedbacks" />

      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder={t("Search feedbacks...")}
              onChange={handleChange}
              value={SearchTerm}
            />
          </Search>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        {Feedback.map(FeedbackCard)}
      </Grid>
    </React.Fragment>
  );
}

export default Default;
