import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { backgrounds, darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import stringData from "../stringFile";

import {
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField as TF,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
  LocationOn as LocationPointerIcon,
  Security as SecurityIcon,
  Refresh as RefreshIcon,
  Add as AddIcon,
  NoteAdd as AddNoteIcon,
  Edit as EDT,
  Delete as DT,
  FindInPage as RI,
  Receipt as InvoiceIcon,
} from "@material-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ReviewIcon = styled(RI)({
  cursor: "pointer",
});
const DeleteIcon = styled(DT)({
  cursor: "pointer",
});
const EditIcon = styled(EDT)({
  cursor: "pointer",
});

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});

const TextField = styled(TF)({
  backGroundColor: "rgba(0,0,0,0)",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function DashboardUsers() {
  const [addingRole, setAddingRole] = useState(false);
  const refresh = () => {
    displayRoles();
  };
  const [value, setValue] = React.useState<Date | null>(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    React.useState(false);

  const { t } = useTranslation();
  const [viewAddRole, setAddRole] = React.useState("none");

  const handleClickOpen = () => {
    setRoleAction("Add Role");
    setEditRole("none");
    setViewRoleDetail("none");
    setAddRole("block");
  };

  const handleClose = () => {
    setDisplayDeleteConfirmation(false);
    setDisplayMessage(false);
  };

  const [roleName, setRoleName] = useState("");
  const handleRoleName = (e: any) => {
    setRoleName(e.target.value);
  };
  useEffect(() => {
    displayRoles();
    getUsers();
  }, []);

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      console.log(authorization_token);
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/auth/users`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setUsers(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const displayRoles = () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/auth/roles`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setRoles(res.data);
          })
          .catch((error) => {
            console.log(error);
            setMessage("Couldn't load roles data.");
            setDisplayMessage(true);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const navigate = useNavigate();
  const [roleAction, setRoleAction] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState("");

  const addRole = () => {
    setAddingRole(true);
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .post(
            `https://test-adminapi.meetleelou.com/api/auth/add-role`,
            { roleName: roleName },
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setMessage(stringData.info.createRoleSuccess);
            setDisplayMessage(true);
            displayRoles();

            setAddingRole(false);
          })
          .catch((error) => {
            console.log(error);
            setMessage(stringData.info.createRoleError);
            setDisplayMessage(true);
            setAddingRole(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const handleConfirmDeleteRole = () => {
    setDisplayDeleteConfirmation(false);
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .post(
            `https://test-adminapi.meetleelou.com/api/auth/role/${deleteRole}`,
            { roleName: roleName },
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setMessage(stringData.info.deleteRoleSuccess);

            setDisplayMessage(true);
            setDeleteRole("");
            displayRoles();
          })
          .catch((error) => {
            console.log(error);
            setMessage(stringData.info.deleteRoleError);
            setDisplayMessage(true);
            setAddingRole(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [deleteRole, setDeleteRole] = useState("");
  const handleDeleteRole = (e: any) => {
    setDeleteRole(e.id);
    setDisplayDeleteConfirmation(true);
  };
  const [editRole, setEditRole] = useState("none");
  const [toEdit, setToEdit] = useState([]);
  const [er, setER] = useState("");
  const [ei, setEI] = useState("");
  const handleEditRole = (key: any) => {
    setAddRole("none");
    setViewRoleDetail("none");
    setToEdit(key);
    setER(key.name);
    setEI(key.id);
    setEditRole("block");
  };

  const handleEditRoleName = (e: any) => {
    setER(e.target.value);
  };

  const confirmEditRole = (key: any) => {
    setAddingRole(true);
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .post(
            `https://test-adminapi.meetleelou.com/api/auth/edit-role`,
            { roleName: er, id: ei },
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setAddingRole(false);

            setMessage(stringData.info.editRoleSuccess);
            setDisplayMessage(true);
            displayRoles();
          })
          .catch((error) => {
            setAddingRole(false);

            console.log(error);
            setMessage(stringData.info.editRoleError);
            setDisplayMessage(true);
            setAddingRole(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const rolesCard = (key: any) => {
    return (
      <TableRow>
        <StyledTableCell align="right">
          <AddNoteIcon color="warning" />
        </StyledTableCell>
        <StyledTableCell align="left">{key.name}</StyledTableCell>
        <StyledTableCell align="right">
          <ReviewIcon
            color="disabled"
            sx={{ marginX: "5px" }}
            onClick={() => handleViewRoleDetail(key)}
          />
          <InvoiceIcon color="disabled" sx={{ marginX: "5px" }} />
          <EditIcon
            color="disabled"
            sx={{ marginX: "5px" }}
            onClick={() => handleEditRole(key)}
          />
          <DeleteIcon
            color="disabled"
            sx={{ marginX: "5px" }}
            onClick={() => handleDeleteRole(key)}
          />
        </StyledTableCell>
      </TableRow>
    );
  };

  const [viewRoleDetail, setViewRoleDetail] = useState("none");
  const [selectedRole, setSelectedRole] = useState([] as any);
  const [rd, setRD] = useState([]);
  const handleViewRoleDetail = (key: any) => {
    setSelectedRole(key);
    setEditRole("none");
    setAddRole("none");
    setViewRoleDetail("block");
  };

  const assignedUsers = (key: any) => {
    if (key.roles.includes(selectedRole.normalizedName)) {
      return (
        <Chip
          color="warning"
          size="small"
          sx={{ margin: "5px" }}
          label={key.name}
        ></Chip>
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard Users" />

      <Grid container>
        <Grid item xs={7} direction="column">
          <Card>
            <CardContent style={{ padding: "8px" }}>
              <Grid container>
                <Grid item xs={8}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="warning"
                    onClick={handleClickOpen}
                  >
                    <AddIcon />
                    {stringData.dUsers.createNewRole}
                  </Button>
                  <Link>
                    <Button
                      disabled={true}
                      size="medium"
                      variant="contained"
                      color="warning"
                      sx={{ marginLeft: "5px" }}
                    >
                      <AddIcon />
                      {stringData.dUsers.addPermissions}
                    </Button>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button size="medium" onClick={refresh}>
                    <RefreshIcon color="disabled" />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mt={2}>
            <CardContent sx={{ paddingBottom: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="left">
                      {stringData.dUsers.currentRoles}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {stringData.dUsers.actions}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{roles.map(rolesCard)}</TableBody>
              </Table>
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
                pt={3}
              >
                <Button color="warning">{stringData.button.viewMore}</Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item spacing={6} px={3} xs={5} display={viewAddRole}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-center" }}
                >
                  <AddNoteIcon color="warning" /> {stringData.dUsers.addRole}
                </Grid>
              </Grid>
              <Grid container mt={5}>
                <Grid item xs={4} mt={5}>
                  {stringData.dUsers.nameOfRole}
                </Grid>
                <Grid item xs={8} mt={5}>
                  <TextField
                    type="text"
                    name="text"
                    fullWidth
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    value={roleName}
                    onChange={handleRoleName}
                  />
                </Grid>
                {/*
                <Grid item xs={4} mt={5}>
                  Description{" "}
                </Grid>
                <Grid item xs={8} mt={5}>
                  <TextField
                    type="text"
                    name="text"
                    fullWidth
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                  />
                </Grid>
                <Grid item xs={4} mt={5}>
                  Permissions{" "}
                </Grid>
                <Grid
                  container
                  xs={8}
                  mt={5}
                  style={{ alignItems: "center", justifyContent: "flex-end" }}
                >
                  <Grid item xs={2}>
                    <Checkbox value="remember" color="warning" />{" "}
                  </Grid>
                  <Grid item xs={10}>
                    Manage Credentials
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox value="remember" color="warning" />{" "}
                  </Grid>
                  <Grid item xs={10}>
                    Manage Credentials
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox value="remember" color="warning" />{" "}
                  </Grid>
                  <Grid item xs={10}>
                    Manage Credentials
                  </Grid>
                </Grid>
                */}
                <Grid
                  item
                  xs={12}
                  mt={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    disabled={addingRole}
                    variant="outlined"
                    color="warning"
                    onClick={addRole}
                  >
                    {stringData.dUsers.addRole}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item spacing={6} px={3} xs={5} display={editRole}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-center" }}
                >
                  <AddNoteIcon color="warning" /> {stringData.dUsers.editRole}
                </Grid>
              </Grid>
              <Grid container mt={5}>
                <Grid item xs={4} mt={5}>
                  {stringData.dUsers.nameOfRole}
                </Grid>
                <Grid item xs={8} mt={5}>
                  <TextField
                    type="text"
                    name="text"
                    fullWidth
                    variant="standard"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                    value={er}
                    onChange={handleEditRoleName}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    disabled={addingRole}
                    variant="outlined"
                    color="warning"
                    onClick={() => confirmEditRole(toEdit)}
                  >
                    {stringData.dUsers.editRole}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item spacing={6} px={3} xs={5} display={viewRoleDetail}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-center" }}
                >
                  <AddNoteIcon color="warning" /> {stringData.dUsers.viewRole}
                </Grid>
              </Grid>
              <Grid container mt={5}>
                <Grid item xs={12} mt={5}>
                  <Typography variant="h5">
                    {stringData.dUsers.role}: {selectedRole.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={5}>
                  {stringData.dUsers.createdDate}: {selectedRole.createdOn}
                </Grid>
                <Grid item xs={12} mt={5}>
                  {stringData.dUsers.assignedUsers}:{users.map(assignedUsers)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={displayDeleteConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {stringData.info.deleteRoleMesage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmDeleteRole}
            variant="contained"
            color="error"
          >
            {stringData.button.delete}
          </Button>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={displayMessage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.close}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DashboardUsers;
