import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SelectChangeEvent } from "@material-ui/core/Select";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Select,
  Input,
  MenuItem,
  Switch,
  InputLabel,
  FormControlLabel,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload, Superscript } from "@material-ui/icons";
import { spacing, SpacingProps } from "@material-ui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function CreateForm() {
  interface Admin {
    email: String;
    name: String;
    userName: String;
    phone: String;
    companyId: String;
    roles: [];
    //address:String;
    //country:String;
    //securitySuits: [];
    //profileImage:String;
    //image:String;
    isViewOnly: Boolean;
    zoneIds: String;
  }
  interface Role {
    id: String;
    name: String;
  }
  const initial_values = {
    Email: "",
    Name: "",
    UserName: "",
    Phone: "",
    CompanyId: "00000000-0000-0000-0000-000000000000",
    Roles: [],
    Address: "",
    Country: "",
    SecuritySuites: "",
    isViewOnly: false,
    ZoneId: "00000000-0000-0000-0000-000000000000",
    ProfileImage: "Profile Image",
    image: "",
  };
  const [values, setValues] = useState(initial_values);
  const handleInputChange = async (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/auth/roles`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setR(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const [roles, setRoles] = React.useState<string[]>([]);
  const [r, setR] = useState([]);

  const handleRoleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setRoles(
      // On autofill we get a the stringified value.
      value
    );
    values.Roles = value;
  };

  const GenerateRoles = (role: Role) => {
    const name: any = role.name ? role.name : "";
    const id: any = role.id ? role.id : "";

    return (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    );
  };

  const [isViewOnly, setIsViewOnly] = useState(false);

  const handleIsViewOnly = (e: any) => {
    if (isViewOnly) {
      setIsViewOnly(false);
      values.isViewOnly = false;
    } else {
      setIsViewOnly(true);
      values.isViewOnly = true;
    }
  };
  const [messageOnChange, setChangeMessage] = React.useState(false);
  const handleClose = () => {
    setChangeMessage(false);
  };
  const [message, setMessage] = useState("");

  const handleCreateAdmin = () => {
    let fields = "";
    let err = false;
    if (values.Name == "") {
      fields += "Name, ";
      err = true;
    }
    if (values.Email == "") {
      fields += "Email, ";
      err = true;
    }
    if (values.UserName == "") {
      fields += "User Name, ";
      err = true;
    }
    if (values.Phone == "") {
      fields += "Phone, ";
      err = true;
    }
    if (values.CompanyId == "") {
      fields += "Company Id, ";
      err = true;
    }
    if (values.Roles.length == 0) {
      fields += "Roles, ";
      err = true;
    }
    const authorization_token = localStorage.getItem("auth_session");

    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    if (auth_token !== "") {
      if (err) {
        setMessage("Required Fields: " + fields);
        setChangeMessage(true);
      } else {
        const form = new FormData();
        const initial_values = {
          Email: "",
          Name: "",
          UserName: "",
          Phone: "",
          CompanyId: "00000000-0000-0000-0000-000000000000",
          Roles: [],
          Address: "",
          Country: "",
          SecuritySuites: "",
          isViewOnly: false,
          ZoneId: "00000000-0000-0000-0000-000000000000",
          ProfileImage: "Profile Image",
          image: "",
        };
        form.append("Email", values.Email);
        form.append("Name", values.Name);

        form.append("userName", values.UserName);
        form.append("Phone", values.Phone);
        form.append("CompanyId", values.CompanyId);
        form.append("Roles", String(values.Roles));
        form.append("Address", values.Address);
        form.append("Country", values.Country);
        form.append("SecuritySuites", values.SecuritySuites);
        form.append("isViewOnly", String(values.isViewOnly));
        form.append("ZoneId", values.ZoneId);
        form.append("ProfileImage", values.ProfileImage);
        form.append("image", values.image);

        axios
          .post(
            `https://test-adminapi.meetleelou.com/api/auth/user/new`,
            form,
            {
              headers: {
                Authorization: `Bearer ${auth_token.token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            setMessage("Admin successfully created.");
            setChangeMessage(true);
            setValues(initial_values);
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.request);
            console.log(error.message);
            setMessage("Error occured while creating new admin.");
            setChangeMessage(true);
          });
      }
    }
  };

  return (
    <div>
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <CenteredContent>
                <BigAvatar alt="" src={values.image} />
                <input
                  accept="image/*"
                  id="raised-button-file"
                  multiple
                  type="file"
                  name="image"
                  value={values.image}
                  onChange={handleInputChange}
                />
                <label htmlFor="raised-button-file">
                  <Typography variant="caption" display="block" gutterBottom>
                    For best results, use an image at least 128px by 128px in
                    .jpg format
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>
            <Grid item xs={5} p={2}>
              <Grid item xs={12}>
                <TextField
                  id="full-name"
                  label="Full Name"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  onChange={handleInputChange}
                  value={values.Name}
                  name="Name"
                  fullWidth
                  my={2}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="username"
                  label="User Name"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  onChange={handleInputChange}
                  value={values.UserName}
                  name="UserName"
                  fullWidth
                  my={2}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  label="Phone"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  onChange={handleInputChange}
                  value={values.Phone}
                  name="Phone"
                  fullWidth
                  required={true}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  onChange={handleInputChange}
                  value={values.Email}
                  name="Email"
                  required={true}
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="company_id"
                  label="Company Id"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  value={values.CompanyId}
                  name="CompanyId"
                  onChange={handleInputChange}
                  disabled
                  fullWidth
                  my={2}
                  required={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5} p={2} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  id="country"
                  label="Country"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  fullWidth
                  name="Country"
                  onChange={handleInputChange}
                  value={values.Country}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  label="Address"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  fullWidth
                  name="Address"
                  onChange={handleInputChange}
                  value={values.Address}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="security_suits"
                  label="Security Suits"
                  variant="standard"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important" }}
                  fullWidth
                  name="SecuritySuites"
                  onChange={handleInputChange}
                  value={values.SecuritySuites}
                  my={2}
                />
              </Grid>
              <Grid item xs={12} py={2}>
                <InputLabel id="admin_roles">Admin Roles *</InputLabel>
                <Select
                  required={true}
                  labelId="admin_roles"
                  id="admin_roles"
                  multiple
                  variant="standard"
                  color="warning"
                  size="small"
                  fullWidth
                  value={roles}
                  onChange={handleRoleChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {r.map(GenerateRoles)}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      required={true}
                      checked={isViewOnly}
                      onChange={handleIsViewOnly}
                      name="isViewOnly"
                    />
                  }
                  label="View Only *"
                />
              </Grid>
            </Grid>
            <Grid />
          </Grid>
          <Grid container>
            <Grid item xs={12} px={2} mb={6} justifyContent="center">
              <Button
                size="medium"
                onClick={handleCreateAdmin}
                color="warning"
                variant="contained"
              >
                Add Admin
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={messageOnChange}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CreateAdmin() {
  return (
    <React.Fragment>
      <Helmet title="Create Admin" />

      <CreateForm />
    </React.Fragment>
  );
}

export default CreateAdmin;
