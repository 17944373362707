import React from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { MoreVertical } from "react-feather";
import { VectorMap } from "react-jvectormap";
import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Theme,
  Grid,
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import "../../../vendor/jvectormap.css";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const MapContainer = styled.div`
  height: 344px;
`;

const Card = styled(MuiCard)(spacing);
const BorderLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 255, 1)",
  },
}));
const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-top: 0;
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
const Button = styled(MuiButton)(spacing);
const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 100px;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;
const LinearProgress = styled(MuiLinearProgress)(spacing);

function WorldMap(props: { theme: ThemeProps<Theme> & { palette: any } }) {
  const options = {
    map: "world_mill",
    regionStyle: {
      initial: {
        fill:
          props.theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "#e3eaef",
      },
    },
    series: {
      markers: [
        {
          attribute: "r",
          scale: [5, 20],
          values: [60, 6, 54, 100],
          normalizeFunction: "polynomial",
        },
      ],
    },
    backgroundColor: "transparent",
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 9,
        fill: "#ffff",
        "fill-opacity": 1,
        stroke: "#383f47",
        "stroke-width": 1,
        "stroke-opacity": 0.4,
      },
      hover: {
        stroke: props.theme.palette.secondary.main,
        "stroke-width": 1,
        "fill-opacity": 1,
      },
    },
    zoomOnScroll: false,
    markers: [
      {
        latLng: [39.904202, 116.407394],
        name: "Beijing",
        value: 10,
        style: { fill: "rgba(0,0,255,0.2)", stroke: "rgba(0,0,255,1)", r: 20 },
      },
      {
        latLng: [28.70406, 77.102493],
        name: "Delhi",
        value: 20,
        style: {
          fill: "rgba(255,0,255,0.2)",
          stroke: "rgba(255,0,255,1)",
          r: 20,
        },
      },
      {
        latLng: [41.00824, 28.978359],
        name: "Istanbul",
        value: 40,
        style: {
          fill: "rgba(255,255,255,0.2)",
          stroke: "rgba(255,255,255,1)",
          r: 20,
        },
      },
      {
        latLng: [40.7127837, -74.0059413],
        name: "New York",
        value: 100,
        style: {
          fill: "rgba(0,0,255,0.2)",
          stroke: "rgba(0,0,255,1)",
          r: 20,
        },
      },
    ],
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={<SmallButton size="small">View Full Report</SmallButton>}
        title="SOS by Country"
      />
      <CardContent>
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <MapContainer>
                <VectorMap {...options} />
              </MapContainer>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              Russia
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
              color={grey[500]}
            >
              55,000 of 1M
            </Grid>
          </Grid>
          <Grid container mb={3}>
            <Grid item xs={12}>
              <BorderLinearProgress variant="determinate" value={30} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              Australia
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
              color={grey[500]}
            >
              30,000 of 1M
            </Grid>
          </Grid>
          <Grid container mb={3}>
            <Grid item xs={12}>
              <BorderLinearProgress variant="determinate" value={30} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              Algeria
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
              color={grey[500]}
            >
              98,678 of 1M
            </Grid>
          </Grid>
          <Grid container mb={3}>
            <Grid item xs={12}>
              <BorderLinearProgress variant="determinate" value={30} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withTheme(WorldMap);
