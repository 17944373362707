import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import Actions from "./Actions";
import { spacing, width } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import FilterDialog from "./FilterDialog";
import AlertDialog from "./AlertDialog";
import UserNetworkTabs from "./UserNetworkTabs";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
} from "@material-ui/icons";
import {
  CircularProgress,
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as DefaultCard,
} from "@material-ui/core";
import UsersList from "../dashboardusers/UsersList";

const StyledTableCell = styled(TableCell)({
  padding: "5px",
});
const FixedWidthTableCell = styled(StyledTableCell)({
  width: "50%",
});
export type User = {};

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

const SearchField = (props: any) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        placeholder={stringData.inputPlaceholder.leelouUserSearch}
        onChange={props.oc}
        value={props.value}
      />
    </Search>
  );
};

function LeelouUsersList(props: any) {
  interface User {
    Avatar: String;
    Country: String;
    CreatedDate: String;
    DeviceType: String;
    DisplayName: String;
    Email: String;
    FirstName: String;
    LastName: String;
    LastOnline: String;
    LastUpdate: String;
    Latitude: String;
    Longitude: String;
    MobileCountryCode: String;
    MobileNumber: String;
    UserId: 21;
  }

  const navigate = useNavigate();

  const userList: JSX.Element[] = [];
  const [Users, setUsers] = useState([]);
  const [DefaultUsers, setDefaultUsers] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    displayUsers();
  }, []);
  const displayUsers = () => {
    const timeStamp = Math.floor(Date.now() / 1000);
    console.log(timeStamp);
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Dashboard/recent-users/${timeStamp}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setLoading(false);

            setUsers(res.data);
            setDefaultUsers(res.data);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const [Sos, setSos] = useState([]);

  const GetSOS = async () => {
    const timeStamp = Math.floor(Date.now() / 1000);

    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        await axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/list?timestamp=${timeStamp}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setSos(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const [ViewDetail, setViewDetail] = useState(false);
  const [UserDetail, setUserDetail] = useState({} as User);
  const [SearchTerm, setSearchTerm] = useState("");

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultUsers.filter((user: User) =>
        user?.FirstName.toLowerCase().includes(term.toLowerCase())
      );
      setUsers(results);
    } else {
      setUsers(DefaultUsers);
    }
    setSearchTerm(term);
  };

  const [UserNetwork, setUserNetwork] = useState([]);

  const viewUserDetail = (key: User) => {
    setUserDetail(key);
    setViewDetail(true);
  };
  const [sortByNameType, setsortByNameType] = useState(1);
  const handleSortByName = (type: number) => {
    if (type) {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.FirstName.toLowerCase()[0] < b.FirstName.toLowerCase()[0]
          ? -1
          : 1;
      });
      setUsers(newdata);
      setsortByNameType(0);
    } else {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.FirstName.toLowerCase()[0] < b.FirstName.toLowerCase()[0]
          ? 1
          : -1;
      });
      setUsers(newdata);
      setsortByNameType(1);
    }
  };

  //Sort the sos list by user id.
  const [sortByUserIdType, setsortByUserIdType] = useState(1);
  const handleSortByUserId = (type: number) => {
    if (type) {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.userId - b.userId;
      });
      setUsers(newdata);
      setsortByUserIdType(0);
    } else {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return b.userId - a.userId;
      });
      setUsers(newdata);
      setsortByUserIdType(1);
    }
  };

  //Sort the sos list by user id.
  const [sortByEmailType, setsortByEmailType] = useState(1);
  const handleSortByEmail = (type: number) => {
    if (type) {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.Email.toLowerCase()[0] < b.Email.toLowerCase()[0] ? -1 : 1;
      });
      setUsers(newdata);
      setsortByEmailType(0);
    } else {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.Email.toLowerCase()[0] < b.Email.toLowerCase()[0] ? 1 : -1;
      });
      setUsers(newdata);
      setsortByEmailType(1);
    }
  };

  const UserCard = (key: User) => {
    const registered_date = new Date(String(key?.CreatedDate));
    const last_online = new Date(String(key?.LastOnline));
    return (
      <Grid item xs={12} sm={12} py={1}>
        <Card onClick={() => viewUserDetail(key)}>
          <CardContent style={{ padding: "8px" }}>
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems="center">
                <BigAvatar
                  alt={String(key?.FirstName)}
                  src={
                    "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                    String(key?.Avatar)
                  }
                />
              </Grid>
              <Grid item xs={3} alignItems="left">
                <Typography variant="body2">{key?.DisplayName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" color={grey[500]}>
                  {key?.UserId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {key?.Email}
                </Typography>
              </Grid>
              <Grid
                item
                style={{ display: "flex", justifyContent: "flex-end" }}
                xs={1}
              >
                <AlertDialog />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Leelou App Users" />

      <Grid container>
        <Grid item xs={6} spacing={3} direction="column">
          <Grid container xs={12}>
            <Grid item xs={9}>
              <SearchField oc={handleChange} value={SearchTerm} />
            </Grid>
            <Grid item xs={3}>
              <FilterDialog data={DefaultUsers} callback={setUsers} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} mt={3}>
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems="center"></Grid>
              <Grid
                item
                xs={3}
                onClick={() => handleSortByName(sortByNameType)}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="body2"
                  color={grey[800]}
                  alignItems="center"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {stringData.tableHeader.name}{" "}
                  {sortByNameType ? (
                    <Ascending></Ascending>
                  ) : (
                    <Descending></Descending>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleSortByUserId(sortByUserIdType)}
              >
                <Typography
                  variant="body2"
                  color={grey[800]}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {stringData.tableHeader.userId}{" "}
                  {sortByUserIdType ? (
                    <Ascending></Ascending>
                  ) : (
                    <Descending></Descending>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleSortByEmail(sortByEmailType)}
              >
                <Typography
                  variant="body2"
                  color={grey[800]}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {stringData.tableHeader.email}{" "}
                  {sortByEmailType ? (
                    <Ascending></Ascending>
                  ) : (
                    <Descending></Descending>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                style={{ display: "flex", justifyContent: "center" }}
                xs={1}
              >
                {stringData.tableHeader.actions}
              </Grid>
            </Grid>
          </Grid>
          {Loading ? (
            <Grid
              item
              xs={12}
              py={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress color="warning" size={30} />
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            xs={12}
            my={2}
            sx={{
              maxHeight: "70vh",
              overflowY: "scroll",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            {Users.map(UserCard)}
            <Grid container xs={12} justifyContent="center" py={3}>
              {Loading ? (
                <></>
              ) : (
                <Grid
                  item
                  xs={8}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  alignItems="center"
                >
                  <Button disabled={true} variant="outlined">
                    {stringData.button.noMoreData}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          spacing={6}
          px={3}
          xs={6}
          display={"block"}
          style={{ position: "sticky" }}
        >
          <Grid item xs={12}>
            {ViewDetail ? (
              <UserNetworkTabs data={UserDetail} SoS={Sos} />
            ) : (
              <Card sx={{ width: "100%" }} pt={0}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    p={5}
                  >
                    <CardContent>
                      <Typography variant="h5" color={grey[500]}>
                        {stringData.info.userViewDetail}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>{" "}
      </Grid>
    </React.Fragment>
  );
}

export default LeelouUsersList;
